
import GLazyImage from '../g-lazy-image';
import GButtonV2 from '../g-button-v2';

export default {
  name: 'g-card-landscape-rounded-corners',
  components: {
    GLazyImage,
    GButtonV2,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    linkText: {
      type: String,
      required: false,
      default: null,
    },
  },
};
